/**
 * Created by Karol Kluska on 12.06.17.
 */

function availabilityGraphicNavigationController() {
  var $this = this;

  $this.$onInit = function () {
    $this.monthsLength = $this.to.diff($this.from, 'months') + 1;
    $this.nextMonth = nextMonth;
    $this.prevMonth = prevMonth;
  };

  function nextMonth() {
    var from = moment($this.from).add($this.monthsLength, 'months');
    var to = moment($this.to).add($this.monthsLength, 'months');
    $this.onPeriodChange({from: from, to: to});
  }

  function prevMonth() {
    var from = moment($this.from).subtract($this.monthsLength, 'months');
    var to = moment($this.to).subtract($this.monthsLength, 'months');
    $this.onPeriodChange({from: from, to: to});
  }
}

angular
  .module('app')
  .component('availabilityGraphicNavigation', {
    templateUrl: 'app/availability-graphic/navigation/navigation.html',
    controller: availabilityGraphicNavigationController,
    bindings: {
      from: '<',
      to: '<',
      onPeriodChange: '&'
    }
  });
