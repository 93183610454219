/**
 * Created by Karol Kluska on 12.06.17.
 */

function availabilityGraphicCalendarController() {
  var weekdays = moment.weekdaysMin();
  weekdays.push(weekdays.shift());
  var $this = this;

  $this.weekdays = weekdays;
  $this.$onChanges = function (changes) {
    if (angular.isDefined(changes.offers.currentValue)) {
      $this.days = [];
      $this.daysInMonth = moment($this.month).daysInMonth();
      $this.firstDayOfMonth = moment($this.month).startOf('month');
      $this.lastDayOfMonth = moment($this.month).endOf('month');
      $this.weeksInMonth = getNumberOfWeekInMonth($this.lastDayOfMonth);
      updateDays();
      $this.weeks = getWeeks();
    }
  };

  function updateDays() {
    for (var i = 0; i < $this.daysInMonth; i++) {
      var date = moment($this.firstDayOfMonth).add(i, 'days');
      var yesterdayOffer = getOffer(moment(date).subtract(1, 'day'));
      var todayOffer = getOffer(moment(date));

      var isPast = moment(date).isBefore(moment(), 'day');
      var isOccupied = yesterdayOffer.allotment === 0 && todayOffer.allotment === 0;
      var isOccupiedStart = yesterdayOffer.allotment !== 0 && todayOffer.allotment === 0;
      var isOccupiedEnd = yesterdayOffer.allotment === 0 && todayOffer.allotment !== 0;
      $this.days[i] = {};
      $this.days[i].date = moment(date).format('YYYY-MM-DD');
      $this.days[i].past = isPast;
      $this.days[i].free = !isPast && todayOffer.allotment > 0;
      $this.days[i].occupiedStart = !isPast && isOccupiedStart;
      $this.days[i].occupied = !isPast && isOccupied;
      $this.days[i].occupiedEnd = !isPast && isOccupiedEnd;
      $this.days[i].price = getPriceWithCurrency($this.currency, todayOffer.price);
    }
  }

  function getNumberOfWeekInMonth(date) {
    return Math.ceil((moment(date).date() + $this.firstDayOfMonth.isoWeekday() - 1) / 7);
  }

  function getWeeks() {
    var result = {};
    for (var i = 0; i < $this.weeksInMonth; i++) {
      result[i] = getDaysInWeek(i + 1);
    }
    return result;
  }

  function getOffer(date) {
    date = moment(date).format('YYYY-MM-DD');
    return angular.isUndefined($this.offers[date]) ? {} : $this.offers[date];
  }

  function getDaysInWeek(week) {
    var days = $this.days.filter(function (day) {
      return getNumberOfWeekInMonth(day.date) === week;
    });

    while (days.length < 7) {
      if (week === 1) {
        days.unshift({});
      } else {
        days.push({});
      }
    }

    return days;
  }

  function getPriceWithCurrency(currencyPattern, price) {
    if (angular.isUndefined(currencyPattern)) {
      return null;
    }
    price = parseFloat(price).toFixed(2);
    return currencyPattern.replace(/{.*?}/, parseFloat(price));
  }
}

angular
  .module('app')
  .component('availabilityGraphicCalendar', {
    templateUrl: 'app/availability-graphic/calendar/calendar.html',
    controller: availabilityGraphicCalendarController,
    bindings: {
      month: '<',
      offers: '<',
      inRow: '<',
      currency: '<'
    }
  });
