/**
 * Created by Karol Kluska on 05.07.17.
 */

angular
  .module('app')
  .component('app', {
    template: '' +
    '<custom-user-css stylesheet="$ctrl.stylesheet"></custom-user-css>' +
    '<ui-view></ui-view>',
    bindings: {
      stylesheet: '<'
    }
  })
  .directive('customUserCss', customUserCss);

function customUserCss() {
  return {
    restrict: 'AE',
    controller: function () {},
    controllerAs: 'ctrl',
    bindToController: {
      stylesheet: '<'
    },
    link: link
  };

  function link(scope, element, attr, ctrl) {
    // eslint-disable-next-line
    angular.element(document.querySelector('head')).append('<style type="text/css">' + ctrl.stylesheet + '</style>');
  }
}
