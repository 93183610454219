angular
  .module('app', ['ui.router', 'angularMoment', 'restangular'])
  .config(RestangularProviderConfig)
  .run(run);

function RestangularProviderConfig(RestangularProvider) {
  var apiUrl;
  // eslint-disable-next-line
  if (['http://localhost'].indexOf(window.location.origin) === -1) {
    apiUrl = 'https://dev.kwhotel.com/api/v2/open/';
  } else {
    apiUrl = 'http://web.kwhotel/app_dev.php/api/v2/open/';
  }
  RestangularProvider.setBaseUrl(apiUrl);
}

function run() {
}
