/**
 * Created by Karol Kluska on 12.06.17.
 */

/** @ngInject */
function availabilityGraphicController(Restangular, $stateParams) {
  moment.locale($stateParams.lang);

  var $this = this;
  $this.from = getDateFromURL() || moment().startOf('month');
  $this.monthsLength = getMonthsLengthFromURL() || 1;
  $this.calendarsInRow = getColumnsCountFromURL() || 1;
  $this.to = moment($this.from).add($this.monthsLength - 1, 'months').endOf('month');
  $this.getMonths = getMonths;
  $this.changePeriod = changePeriod;
  getOffers($this.from, $this.to);

  function getDateFromURL() {
    var from = $stateParams.from;
    if (isValidDate(from)) {
      return moment(from).startOf('month');
    }
    return false;

    function isValidDate(stringDate) {
      var now = new Date();
      return (new Date(stringDate) !== 'Invalid Date' && !isNaN(new Date(stringDate)) && now.getTime() < new Date(stringDate).getTime());
    }
  }

  function getMonthsLengthFromURL() {
    var monthsLength = $stateParams.monthsLength;
    if (isValidMonthsLength(monthsLength)) {
      return parseInt(monthsLength, 10);
    }
    return false;

    function isValidMonthsLength(monthsLength) {
      return (!isNaN(monthsLength) && monthsLength > 0 && monthsLength <= 12);
    }
  }

  function getColumnsCountFromURL() {
    var columnsCount = $stateParams.columnsCount;
    if (isValidColumnsCount(columnsCount)) {
      return parseInt(columnsCount, 10);
    }
    return false;

    function isValidColumnsCount(columnsCount) {
      return (!isNaN(columnsCount) && columnsCount > 0 && columnsCount <= 4);
    }
  }

  function getMonths() {
    var monthsLength = Math.abs($this.from.diff($this.to, 'months')) + 1;
    var result = [];

    for (var i = 0; i < monthsLength; i++) {
      result.push(moment($this.from).add(i, 'months').format('YYYY-MM-DD'));
    }

    return result;
  }

  function changePeriod(from, to) {
    if (moment(from).isSameOrAfter(moment(), 'month')) {
      getOffers(from, to);
    }
  }

  function getOffers(from, to) {
    Restangular.all('offers/calendar').post({
      user_id: $stateParams.userId,
      start: moment(from).startOf('month').subtract(1, 'day').format('YYYY-MM-DD'),
      end: moment(to).endOf('month').add(1, 'day').format('YYYY-MM-DD'),
      group_id: $stateParams.groupId,
      rateplan_id: $stateParams.ratePlanId
    })
      .then(function (response) {
        $this.from = moment(from).startOf('month');
        $this.to = moment(to).endOf('month');
        $this.offers = response;
      });
  }
}

angular
  .module('app')
  .component('availabilityGraphic', {
    templateUrl: 'app/availability-graphic/availability-graphic.html',
    controller: availabilityGraphicController,
    bindings: {
      userCurrency: '<'
    }
  });
