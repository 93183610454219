angular
  .module('app')
  .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      abstract: true,
      component: 'app',
      resolve: {
        stylesheet: function (Restangular, $stateParams) {
          return Restangular.one('customer-css').get({
            user_id: $stateParams.userId
          })
            .then(function (response) {
              return response;
          });
        }
      }
    })
    .state('app.availability-graphic', {
      url: '/{userId:[a-fA-F0-9]{32}}/{lang:[a-z]{2}}/{groupId:[0-9]+}/{ratePlanId:[0-9]+}?from&monthsLength&columnsCount',
      component: 'availabilityGraphic',
      resolve: {
        userCurrency: function (Restangular, $stateParams) {
          return Restangular.all('settings/get').post({
            user_id: $stateParams.userId,
            settings: [
              {
                bind_variables: true,
                info_lvl: 99,
                onlyCurrent: true
              }
            ]
          })
            .then(function (response) {
              return response.paymentInfo.const_currency;
            });
        }
      }
    });
}
